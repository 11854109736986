
  import {prepareImageUrl} from '@/helper/ImageHelper';

  export default {
    name: 'SectionHomepageContentCards',
    components: {
      HomepageContentCard: () => import('@/components/HomepageContentCard'),
    },
    props: {
      section: {
        type: Object,
        required: false,
        default: () => {},
      },
      loggedIn: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    serverCacheKey(props) {
      // Deaktiviere das Caching, wenn der Benutzer eingeloggt ist
      if (props.loggedIn) {
        return false;
      }

      // Ansonsten generiere einen eindeutigen Cache-Schlüssel
      return 'section-homepage-content-cards-' + props.section.id;
    },
    data: () => ({
      registrationCard: {},
      profileCard: {},
      jobListingCard: {},
    }),
    created() {
      // prepare data for output
      this.registrationCard = {
        title: this.section.registrationCard.title,
        content: this.section.registrationCard.subline,
        image: {
          url: this.section.registrationCard.image
            ? prepareImageUrl(this.section.registrationCard.image.url)
            : '',
          alt: this.section.registrationCard.image.alternativeText || '',
        },
        buttonText: this.section.registrationCard.button_text,
        cardStyle: this.section.registrationCard.card_style,
      };

      this.profileCard = {
        title: this.section.profileCard.title,
        content: this.section.profileCard.subline,
        image: {
          url: this.section.profileCard.image
            ? prepareImageUrl(this.section.profileCard.image.url)
            : '',
          alt: this.section.profileCard.image.alternativeText || '',
        },
        buttonText: this.section.profileCard.button_text,
        cardStyle: this.section.profileCard.card_style,
      };

      this.jobListingCard = {
        title: this.section.jobListingCard.title,
        content: this.section.jobListingCard.subline,
        image: {
          url: this.section.jobListingCard.image
            ? prepareImageUrl(this.section.jobListingCard.image.url)
            : '',
          alt: this.section.jobListingCard.image.alternativeText || '',
        },
        buttonText: this.section.jobListingCard.button_text,
        cardStyle: this.section.jobListingCard.card_style,
      };
    },
  };
